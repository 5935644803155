import Layout from '@components/common/layout';
import withMetaTags from '@hocs/with-meta-tags';
import getStaticMetaProps from '@utils/get-static-meta-props';
import HomePage from '@views/home-page';

export const getStaticProps = getStaticMetaProps('/');

const Home: React.FC = () => {
  return (
    <Layout withoutFooterBigCircle>
      <HomePage />
    </Layout>
  );
};

export default withMetaTags('home')(Home);
