import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@keyframes expandReadMore': {
    '0%': {
      marginTop: 0,
      marginLeft: -12,
      paddingRight: 12,
      maxHeight: 0,
      opacity: 0,
    },
    '100%': {
      marginTop: 12,
      marginLeft: 0,
      paddingRight: 0,
      maxHeight: 500,
      opacity: 1,
    },
  },
  '@keyframes scroll': {
    '0%': { transform: 'translateX(0)' },
    '100%': {
      transform: 'translateX(calc(-1 * var(--partners-mobile-width) * 5))',
    },
  },
  home: {
    marginTop: 'calc(-1 * var(--header-height))',
    paddingTop: 'var(--header-height)',
  },
  container: {
    position: 'relative',
  },
  reasonWrapper: {
    backgroundColor: theme.palette.background.paper,
  },
  bottomCardsWrapper: {
    padding: '30px 0 80px',
  },
  blueDot: {
    color: theme.palette.primary.dark,
  },
  figureOnTopImageMobile: {
    width: 268,
    right: 0,
    bottom: 371,
  },
  figureOnTopImage: {
    display: 'none',
  },
  figureOnTopCircleOne: {
    bottom: 113,
    left: 99,
    width: 460,
    height: 460,
  },
  figureOnTopCircleTwo: {
    bottom: 322,
    left: -177,
    width: 212,
    height: 212,
  },
  figureOnTopCircleThree: {
    bottom: -22,
    left: -26,
    width: 48,
    height: 48,
  },
  figureOneImage: {
    height: 240,
    bottom: 0,
    left: 75,
  },
  figureOneCircleOne: {
    width: 696,
    height: 696,
    bottom: '-470px',
    left: -285,
  },
  figureTwoImage: {
    bottom: 0,
    right: 26,
    width: 205,
    height: 232,
  },
  figureTwoCircleOne: {
    display: 'block',
    bottom: -62,
    left: 195,
    width: 212,
    height: 212,
    transform: 'translateX(-110%)',
  },
  figureTwoCircleTwo: {
    display: 'block',
    bottom: 188,
    right: 44,
    width: 60,
    height: 60,
  },
  servicesCircle: {
    display: 'none',
  },
  wishes: {
    position: 'relative',
    paddingTop: 56,
  },
  wishesTitle: {
    textAlign: 'left',
    fontSize: 48,
  },
  subtitle: {
    width: '100%',
    margin: '16px 0 24px 0',
  },
  funeralLogoWrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    marginBottom: 24,

    '& svg': {
      width: 150,
    },
  },
  logoText: {
    marginBottom: 8,
    color: '#969799',
  },
  simpWillTitle: {
    margin: '80px 0 24px',
  },
  icon: {
    marginLeft: 10,
    fontSize: 18,
    verticalAlign: 'middle',
  },
  wishCards: {
    marginTop: 315,
  },
  personalAdvice: {
    overflow: 'hidden',
  },
  adviceTextWrapper: {
    padding: '72px 0 286px',

    '& h3': {
      fontSize: 32,
    },
  },
  adviceTitle: {
    marginBottom: 24,
  },
  adviceDescriptionTwo: { margin: '8px 0 0' },
  loudspeaker: {
    display: 'none',
  },
  mostPopular: {
    backgroundColor: theme.palette.background.paper,
    padding: '40px 0',

    '& h3': {
      marginBottom: 24,
    },
  },
  question: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 16,
    boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.07)',
    padding: 24,
    paddingBottom: 56,
    marginTop: 16,
    position: 'relative',

    '& summary': {
      outline: 'none',

      '&::-webkit-details-marker': {
        display: 'none',
      },
    },

    '&[open]': {
      '& $questionBody': {
        animationName: '$expandReadMore',
        animationDuration: '0.4s',
      },
      '& $questionHead $readMore:after': {
        content: '"Show less"',
      },
    },
  },
  questionHead: {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
  },
  summary: {
    fontWeight: 'bold',
  },
  readMore: {
    position: 'absolute',
    bottom: 24,
    left: 24,

    '&:after': {
      content: '"Read more"',
    },
  },
  questionBody: {
    margin: '12px 0 0',
  },
  description: {
    marginBottom: 40,
    color: theme.palette.info.main,
  },
  relative: {
    position: 'relative',
  },
  textCenter: {
    textAlign: 'center',
  },
  community: {
    flexDirection: 'column',
    padding: '80px 0 300px',
    position: 'relative',

    '& h3': {
      marginBottom: 16,
    },
  },
  communityTextWrapper: {
    textAlign: 'center',

    '& p': {
      color: theme.palette.text.disabled,
    },
  },
  charityWrapper: {
    padding: '40px 0',
    backgroundColor: theme.palette.background.paper,

    '& p': {
      marginTop: 40,
    },
  },
  charityPartners: {
    display: 'grid',
    gap: 24,
    gridTemplateColumns: 'repeat(4, 1fr)',

    '& img': {
      maxWidth: '100%',
      alignSelf: 'center',
      justifySelf: 'center',
    },
  },
  partnersTitle: {
    marginBottom: 16,
  },
  partnersWrapper: {
    display: 'block',
    padding: '24px 0',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
  },
  partners: {
    display: 'grid',
    gridTemplateRows: '50px 50px',
    gridTemplateColumns: '1fr 1fr',
    gap: 24,
    alignItems: 'center',
    justifyItems: 'center',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',

      '&:nth-child(3)': {
        margin: '0 auto',
      },
    },
  },
  liveChat: {
    fontSize: 'inherit',
    font: 'inherit',
    fontWeight: 'bold',
    color: theme.palette.secondary.dark,
    textDecoration: 'underline',
  },
  [theme.breakpoints.up('sm')]: {
    figureOnTopImageMobile: {
      display: 'none',
    },
    figureOnTopImage: {
      display: 'block',
      width: 510,
      height: 476,
      left: 228,
      top: 'calc(170px - var(--header-height))',
      transform: 'none',
    },
    figureOnTopCircleOne: {
      width: 765,
      height: 765,
      top: 'calc(-47px - var(--header-height))',
      bottom: 'unset',
      right: '-470px',
      left: 'initial',
    },
    figureOnTopCircleTwo: {
      width: 212,
      height: 212,
      top: 'calc(520px - var(--header-height))',
      bottom: 'unset',
      left: '-192px',
    },
    figureOnTopCircleThree: {
      width: 180,
      height: 180,
      left: 109,
      bottom: 129,
    },
    figureOneImage: {
      height: 248,
      left: 40,
    },
    figureOneCircleOne: {
      left: -390,
      width: 698,
      height: 698,
      bottom: 'initial',
      top: 122,
    },
    wishes: {
      paddingTop: 56,
    },
    wishCards: {
      marginTop: 100,
    },
    subtitle: {
      width: 240,
      margin: '24px 0 40px 0',
    },
    adviceTextWrapper: {
      padding: '80px 0 72px',
      marginLeft: 'auto',
      width: 'calc(100% - 240px)',
    },
    loudspeaker: {
      position: 'absolute',
      display: 'flex',
      top: -40,
      right: 0,
      width: 90,
      height: 90,
      transform: 'translateY(-50%)',
      '& img': {
        width: '100%',
      },
    },
    charityWrapper: {
      '& p': {
        marginTop: 16,
      },
    },
    charityPartners: {
      width: 534,
      gap: '44px 30px',
      margin: '0 auto',
    },
    community: {
      padding: 0,
    },
    communityTextWrapper: {
      width: '65%',
      margin: '0 auto',
      padding: '80px 0 40px',
      '& h3': {
        marginBottom: 8,
      },
    },
    figureTwoImage: {
      bottom: 0,
      right: 0,
      height: 150,
      width: 133,
    },
    figureTwoCircleOne: {
      display: 'none',
    },
    figureTwoCircleTwo: {
      display: 'none',
    },
    question: {
      paddingBottom: 24,
    },
    readMore: {
      position: 'absolute',
      bottom: 'unset',
      left: 'unset',
      top: 24,
      right: 24,
      cursor: 'pointer',
    },
    bottomCardsWrapper: {
      padding: '40px 0 80px',
    },
    partnersTitle: {
      marginBottom: 24,
    },
    partnersWrapper: {
      padding: '32px 0',
    },
    partners: {
      gap: 38,
      gridTemplateRows: '60px 60px',

      '& img': {
        maxWidth: '100%',
        margin: 0,
        alignSelf: 'center',
        justifySelf: 'center',
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    figureOnTopImage: {
      left: 'initial',
      right: '-30px',
      width: 593,
      height: 520,
      top: 0,
    },
    figureOnTopCircleOne: {
      width: 842,
      height: 842,
      top: 'calc(-68px - var(--header-height))',
      right: '-520px',
    },
    figureOnTopCircleTwo: {
      top: 'calc(600px - var(--header-height))',
      left: '-210px',
      width: 220,
      height: 220,
    },
    figureOnTopCircleThree: {
      bottom: -50,
      left: 266,
    },
    simpWillTitle: {
      margin: '88px 0 24px',
    },
    figureOneImage: {
      width: 304,
      height: 310,
      left: 67,
    },
    figureOneCircleOne: {
      width: 626,
      height: 626,
      left: -168,
      top: 200,
    },
    figureTwoImage: {
      width: 160,
      height: 180,
    },
    wishesTitle: {
      fontSize: 72,
    },
    wishCards: {
      marginTop: 152,
    },
    subtitle: {
      width: 400,
    },
    adviceTextWrapper: {
      width: 'calc(100% - 400px)',
      padding: '88px 0',
      '& h3': {
        fontSize: 48,
      },
    },
    funeralLogoWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      marginBottom: 24,
    },
    logoText: {
      marginLeft: 8,
      marginTop: 6,
      marginBottom: 0,

      '&:before': {
        content: "'- '",
      },
    },
    loudspeaker: {
      width: 132,
      height: 132,
    },
    bottomCardsWrapper: {
      padding: '64px 0 88px',
    },
    servicesCircle: {
      display: 'block',
      height: 654,
      width: 624,
      top: 20,
      left: -554,
      transform: 'translateY(-50%)',
    },
    mostPopular: {
      '& h3': {
        marginBottom: 32,
      },
    },
    partnersWrapper: {
      padding: '48px 0',
    },
    partners: {
      gridTemplateRows: 80,
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  [theme.breakpoints.up('lg')]: {
    figureOnTopImage: {
      height: 514,
      width: 648,
      top: 'calc(140px - var(--header-height))',
      right: -54,
    },
    figureOnTopCircleOne: {
      top: 'calc(-68px - var(--header-height))',
      right: -453,
    },
    figureOnTopCircleTwo: {
      width: 340,
      height: 340,
      top: 360,
      left: -420,
    },
    figureOnTopCircleThree: {
      bottom: -56,
      left: 324,
    },
    figureOneImage: {
      height: 366,
      left: 87,
    },
    figureOneCircleOne: {
      width: 1038,
      height: 1038,
      left: '-420px',
      bottom: '-711px',
    },
    simpWillTitle: {
      margin: '144px 0 24px',
    },
    subtitle: {
      width: 500,
    },
    wishCards: {
      marginTop: 178,
    },
    adviceTextWrapper: {
      padding: '144px 0',
      maxWidth: 540,
    },
    loudspeaker: {
      width: 158,
      height: 158,
    },
    charityWrapper: {
      '& p': {
        marginTop: 24,
      },
    },
    charityPartners: {
      gridTemplateColumns: 'repeat(8, 1fr)',
      width: '100%',
    },
    communityTextWrapper: {
      padding: '144px 0 40px',
    },
    figureTwoImage: {
      width: 205,
      height: 232,
      right: -60,
    },
    bottomCardsWrapper: {
      padding: '104px 0 171px',
    },
    servicesCircle: {
      width: 654,
      left: -717,
      top: 85,
    },
    mostPopular: {
      '& h3': {
        marginBottom: 40,
      },
    },
  },
}));

export default useStyles;
