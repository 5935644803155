import { Container, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import BannerWithCards, {
  CardInfo,
} from '@components/common/banner-with-cards';
import Button from '@components/common/button';
import FeefoRating from '@components/common/feefo-rating';
import Figures, { Circle, Image } from '@components/common/figures';
import Testimonials from '@components/common/testimonials';
import defaultPrices from '@config/constants/prices';
import useTypedSelector from '@hooks/use-typed-selector';
import Chatting from '@public/svg/chatting.svg';
// import CoOp from '@public/svg/co-op.svg';
import Funeral from '@public/svg/funeral.svg';
import House from '@public/svg/house.svg';
import ThumbUp from '@public/svg/thumb-up.svg';
import TippingHand from '@public/svg/tipping-hand.svg';
import Will from '@public/svg/will.svg';
import { getReviews, getRating } from '@store/landings/thunks';
import idNormalize from '@utils/id-normalize';
import useStyles from '@views/home-page/styles';

const serviceCardData: CardInfo[] = [
  {
    icon: <i className="icon-write-will" />,
    iconWithShadow: true,
    title: 'Write your will online',
    description: 'Outline your wishes in just 15 minutes.',
    action: {
      href: '/create-will',
      text: 'Create will now',
    },
    expandable: true,
  },
  {
    icon: <i className="icon-form-doc" />,
    iconWithShadow: true,
    title: 'Arrange a funeral',
    description: 'Speak to a funeral expert today.',
    action: {
      // href: '/arrange-funeral',
      // text: 'Learn more',
      href: '/',
      text: 'Coming soon',
      disabled: true,
    },
    expandable: true,
  },
  {
    icon: <i className="icon-pen" />,
    iconWithShadow: true,
    title: 'Arrange probate, easily',
    description: 'Fixed-fee probate without the hassle.',
    action: {
      href: '/probate',
      text: 'Learn more',
    },
    expandable: true,
  },
];

const whySimpWill: CardInfo[] = [
  {
    icon: <ThumbUp />,
    description:
      'Simpwill is the most cost effective will writing service online, ' +
      'completed in just 15 minutes and unlimited updates.',
  },
  {
    icon: <TippingHand />,
    description: (
      <React.Fragment key="trusted">
        Trusted by the people of the UK to
        <br /> offer support and guidance for you
        <br /> and your family.
      </React.Fragment>
    ),
  },
  {
    icon: <Chatting />,
    description:
      'The team at Simpwill are available 7 days a week to offer expert advice or just a friendly chat!',
  },
];

const popularQuestion = (
  singleWillPrice: number,
  jointWillPrice: number,
  unlimitedPrice: number,
) => [
  {
    summary: 'How much does an online will cost?',
    text: (
      <>
        Simpwill offers the most affordable online will service. It costs as
        little as <b>£{singleWillPrice}</b> for a single will or{' '}
        <b>£{jointWillPrice}</b> for couples, that&apos;s a saving of{' '}
        <b>£{jointWillPrice - singleWillPrice}</b> one of our specialists
        compared to the cost of two single wills. It&apos;s completely free to
        sign up and start writing your will online today - No payment is
        required until you have completed your will and it has been checked by
        our specialists.
      </>
    ),
  },
  {
    summary: 'Can i update my will online, at no extra cost?',
    text: (
      <>
        Simpwill understands that life is ever changing, that&apos;s why our
        online will includes an update services <b>(Estate Update)</b> that
        costs you just <b>£{unlimitedPrice}</b> a year - The first year is
        completely <b>FREE</b>. If you already have a will that needs updating
        or you are looking to create one for the first time, it&apos;s worth
        noting that from a solicitor or another provider you may be charged if
        you would like to update your will now or in the future. With Estate
        Update you can log back into your account at any time and make unlimited
        updates to your will. If you no longer need to make any updates you can
        cancel Estate Update at any time.
      </>
    ),
  },
  {
    summary: 'Is an online will legally binding?',
    text: (
      <>
        It couldn&apos;t be simpler to make your will legally binding. Once your
        will has been checked by a Simpwill specialist you will need to
        download, print and sign it alongside two witnesses. If you are unable
        to download and print your will we will be happy to send it to you in
        the post. When signing your will in front of two witnesses it&apos;s
        important that both of them watch you sign your will at the same time
        and add in their own details and signatures. Should your will be
        contested in the future, your witnesses can testify they were present at
        the time and witnessed you sign your will.
      </>
    ),
  },
];

const charityPartnersLogos = [
  '/png/charityPartners/marie-curie.png',
  '/png/charityPartners/mind-charity.png',
  '/png/charityPartners/macmillan-cancer-support.png',
  '/png/charityPartners/prostate-cancer.png',
  '/png/charityPartners/cancer-research-uk.png',
  '/png/charityPartners/shelter.png',
  '/png/charityPartners/sands.png',
  '/png/charityPartners/save-the-children.png',
];

const services: CardInfo[] = [
  {
    icon: <Will />,
    title: 'Wills',
    description: 'Peace of mind in as little as 15 minutes.',
    action: {
      href: '/create-will',
      text: 'Write my will',
    },
  },
  {
    icon: <House />,
    title: 'Probate',
    description: `Estate administration with ease.`,
    action: {
      href: '/probate',
      text: 'Apply for probate',
    },
  },
  {
    icon: <Funeral />,
    title: 'Funeral Services',
    description: 'Protecting against unexpected costs.',
    action: {
      // href: '/arrange-funeral', // TODO: change it after new funeral discover
      // text: 'Arrange service',
      href: '',
      text: 'Coming soon',
    },
  },
];

const partnersLogos = [
  '/png/partners/anorak.png',
  '/png/partners/country-wide.png',
  '/png/partners/soluco.png',
  '/png/partners/homely.png',
];

const charityPartners = charityPartnersLogos.map((logo) => (
  <img src={logo} alt="logo" key={logo} />
));

const partners = partnersLogos.map((logo) => (
  <img src={logo} alt="logo" key={logo} />
));

const HomePage: React.FC = () => {
  const classes = useStyles();

  const {
    items: reviews,
    overalRating,
    lastFetchedReviews,
    lastFetchedRating,
    isLoading,
  } = useTypedSelector((state) => state.landings);

  const { prices } = useTypedSelector((state) => state.documents);

  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    if (router.query.open_chat && window.tidioChatApi) {
      window.tidioChatApi.open();
    }
  }, [router]);

  useEffect(() => {
    // Fetches if no reviews in state or reviews are older than two hours
    if (reviews.length) {
      if (
        lastFetchedReviews &&
        lastFetchedReviews > Date.now() - 1000 * 60 * 60 * 2
      ) {
        dispatch(getReviews());
      }
    } else {
      dispatch(getReviews());
    }

    // Fetches if no rating in state or rating is older than one two hours
    if (overalRating) {
      if (
        lastFetchedRating &&
        lastFetchedRating > Date.now() - 1000 * 60 * 60 * 2
      ) {
        dispatch(getRating());
      }
    } else {
      dispatch(getRating());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTop: Circle[] = [
    {
      background: 'blueGradient',
      classWrapper: classes.figureOnTopCircleOne,
    },
    {
      background: 'pink',
      classWrapper: classes.figureOnTopCircleTwo,
    },
    {
      background: 'pinkMain',
      classWrapper: classes.figureOnTopCircleThree,
    },
  ];

  const imagesOnTop: Image[] = [
    {
      src: '/png/person-hand-up-small.png',
      classWrapper: classes.figureOnTopImageMobile,
    },
    {
      src: '/png/person-hand-up-desktop.png',
      classWrapper: classes.figureOnTopImage,
    },
  ];

  const figureOne: Circle[] = [
    {
      background: 'blueGradient',
      classWrapper: classes.figureOneCircleOne,
    },
  ];

  const imagesOne: Image[] = [
    {
      src: '/png/person-one.png',
      classWrapper: classes.figureOneImage,
    },
  ];

  const figureTwo: Circle[] = [
    {
      background: 'pink',
      classWrapper: classes.figureTwoCircleOne,
    },
    {
      background: 'pinkMain',
      classWrapper: classes.figureTwoCircleTwo,
    },
  ];

  const servicesCircle: Circle[] = [
    {
      background: 'pink',
      classWrapper: classes.servicesCircle,
    },
  ];

  const imagesTwo: Image[] = [
    {
      src: '/png/person-thumb-up.png',
      classWrapper: classes.figureTwoImage,
    },
  ];

  const popularQuestionsRender = popularQuestion(
    prices?.singleWillPrice || defaultPrices.singleWillPrice,
    prices?.jointWillPrice || defaultPrices.jointWillPrice,
    prices?.unlimitedUpdates || defaultPrices.unlimitedUpdates,
  ).map((question) => (
    <details
      id={`${idNormalize(question.summary)}-details`}
      className={classes.question}
      key={question.summary}
    >
      <summary className={classes.questionHead}>
        <Typography className={classes.summary} variant="body2">
          {question.summary}
        </Typography>

        <Typography
          id={`${idNormalize(question.summary)}-read-more-button`}
          variant="button"
          className={classNames(classes.readMore, 'pink')}
        />
      </summary>
      <div className={classes.questionBody}>{question.text}</div>
    </details>
  ));

  return (
    <>
      <section className={classNames(classes.home, 'overflow-hidden')}>
        <section>
          <Container maxWidth={false} className={classes.container}>
            <div className={classes.wishes}>
              <Figures images={imagesOnTop} circles={onTop} />

              <Typography
                component="h1"
                variant="h2"
                className={classes.wishesTitle}
              >
                Your wishes <br /> made simple
                <span className={classes.blueDot}>.</span>
              </Typography>

              <Typography
                variant="body2"
                component="h3"
                className={classes.subtitle}
              >
                The most cost effective online will writing service. Simple and
                convenient, allowing you to write a will from the comfort of
                your home.
              </Typography>

              {/* <div className={classes.funeralLogoWrapper}>
                <CoOp />
                <Typography
                  variant="body2"
                  component="h3"
                  className={classes.logoText}
                >
                  In partnership with Co-op
                </Typography>
              </div> */}

              <Button
                id="create-will-now-link"
                styleType="containedBlue"
                text="Create will now"
                fullWidthOnMobile
                href="/create-will"
              />
              <section className={classes.wishCards}>
                <BannerWithCards info={serviceCardData} clickableOnMobile />
              </section>
            </div>
          </Container>
        </section>

        <section>
          <Typography
            variant="h3"
            className={classNames(classes.simpWillTitle, classes.textCenter)}
          >
            Why Choose Simpwill?
          </Typography>

          <div className={classes.reasonWrapper}>
            <Container maxWidth={false}>
              <BannerWithCards separated info={whySimpWill} />
            </Container>
          </div>
        </section>

        <FeefoRating overalRating={overalRating} />
      </section>
      <Testimonials reviews={reviews} isLoading={isLoading} />

      <section className={classes.personalAdvice}>
        <Container maxWidth={false} className={classes.relative}>
          <Figures
            images={imagesOne}
            circles={figureOne}
            classWrapper={classes.figureOne}
          />
          <div className={classes.adviceTextWrapper}>
            <Typography variant="h3" className={classes.adviceTitle}>
              Can we help you with something?
            </Typography>

            <Typography variant="body2">
              If we haven&apos;t managed to answer your questions in our FAQ
              section below then please contact one of our friendly team using
              the{' '}
              <button
                id="live-chat-button-home-page"
                className={classes.liveChat}
                type="button"
                onClick={() => window.tidioChatApi.open()}
              >
                live chat
              </button>{' '}
              function.
            </Typography>
            <Typography
              variant="body2"
              className={classes.adviceDescriptionTwo}
            >
              The team will get back to you as soon as they can.
            </Typography>
          </div>
        </Container>
      </section>

      <section className={classes.mostPopular}>
        <Container maxWidth={false}>
          <div className={classes.relative}>
            <div className={classes.loudspeaker}>
              <img src="/png/loudspeaker.png" alt="loudspeaker" />
            </div>
            <Typography variant="h3" className={classes.textCenter}>
              Most Popular Questions
            </Typography>
            {popularQuestionsRender}
          </div>
        </Container>
      </section>

      <section>
        <Container maxWidth={false}>
          <div className={classes.community}>
            <Figures
              images={imagesTwo}
              circles={figureTwo}
              classWrapper={classes.figureTwo}
            />
            <div className={classes.communityTextWrapper}>
              <Typography variant="h3" className={classes.textCenter}>
                Charities
              </Typography>
              <Typography variant="body2">
                Simpwill&rsquo;s community generously leave gift donations
                within their wills, directly linked with JustGiving these
                donations contribute towards the vital funding each charity
                needs to deliver the services they offer.
              </Typography>
            </div>
          </div>
        </Container>
      </section>

      <section className={classes.charityWrapper}>
        <Container maxWidth={false}>
          <div className={classes.charityPartners}>{charityPartners}</div>
          <Typography variant="body2" className={classes.textCenter}>
            And many more charity partners.
          </Typography>
        </Container>
      </section>

      <section className={classes.servicesSection}>
        <Container maxWidth={false} className={classes.relative}>
          <Figures circles={servicesCircle} />
          <div className={classes.bottomCardsWrapper}>
            <BannerWithCards info={services} />
          </div>
        </Container>
      </section>

      <Typography
        variant="h3"
        className={classNames(classes.textCenter, classes.partnersTitle)}
      >
        Our partners
      </Typography>
      <section className={classes.partnersWrapper}>
        <Container maxWidth={false}>
          <div className={classes.partners}>{partners}</div>
        </Container>
      </section>
    </>
  );
};

export default HomePage;
